import React from 'react';
import ProjectDetailsImage from '../Image/ProjectDetailsImage';

function WhyFpoon() {
  return (
    <>
      <section className="why-fpoon">
        <header>
          <h1>Fpoon?</h1>
        </header>
        <p>You did find the link to this site. It&apos;s great to have you here!</p>
        <p>
          This page does nothing but tell the story of why I choose the name for the website domain and also a nickname
          I&apos;d use somewhere else on the internet. It&apos;s simple: everybody wants to be the best in the world.
          But as everybody also knows, no man can be a perfect man. You always have to compromise something to get the
          others, the mixture, and the balance of life. And here is a fpoon, too perfect!
        </p>
        <p>
          <ProjectDetailsImage alt="An image of a fpoon" filename="fpoon_image.jpg" />
          <small>
            <i>
              This image (© Internet) amused me a lot that I decided to use website color palette based on its
              background color :)
            </i>
          </small>
        </p>
        <p>
          <div className="embed-container">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/st21dIMaGMs?si=p9hiQtq6LwKXOfCc&amp;controls=0&amp;start=79&amp;end=92"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              aria-label="A scene from Key & Peele - Continental Breakfast"
              title="Key & Peele - Continental Breakfast"
            />
          </div>
        </p>
        <br />
        <p>
          But you are on{' '}
          <i>
            fp<b>ooo</b>n.com
          </i>
          , not{' '}
          <i>
            fp<b>oo</b>n.com
          </i>
          ? The reason is that I couldn&apos;t afford the price which my domain provider offered. They were asking for{' '}
          <b>$4,850</b> for that “premium” domain name, despite a much lower renewal rate. I&apos;m too broke for that!
        </p>
        <p>
          <ProjectDetailsImage alt="fpoon.com domain price" filename="fpoon_domain.png" />
        </p>
        <p>
          So here we are, at a relative cheap <i>fpooon.com</i>. I hope you didn&apos;t mind typing an extra “o” to
          access the website.
        </p>
        <p>
          That&apos;s pretty much it! Thank you for reading such meaningless information and have a great day!{' '}
          <span role="img" aria-label="Winking Face">
            😉
          </span>
        </p>
      </section>
    </>
  );
}

export default WhyFpoon;
