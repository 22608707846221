import React from 'react';
import SEO from '../components/SEO/SEO';
import Layout from '../components/Layout/Layout';
import WhyFpoon from '../components/WhyFpoon/WhyFpoon';

const headData = {
  title: 'Why Fpoon?',
  description: 'Why do I choose the name Fpoon?',
  lang: 'en',
};

const index = () => (
  <>
    <SEO title={headData.title} description={headData.description} lang={headData.lang} />
    <Layout>
      <WhyFpoon />
    </Layout>
  </>
);
export default index;
